var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "client-title--xxxl"
  }, [_vm._v("캠페인 후원")])]), _c('v-tabs', {
    staticClass: "v-tabs--primary tab--block",
    attrs: {
      "hide-slider": "",
      "height": "auto"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("국내 캠페인")]), _c('v-tab', [_vm._v("국제 캠페인")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "tile": "",
      "outlined": "",
      "to": "/campaign/campaign/infant"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/campaign/infant/thumb.png"
    }
  }), _c('v-card-title', {
    staticClass: "font-size-16 font-size-md-18"
  }, [_vm._v("위기영아지원 캠페인")])], 1)], 1)], 1)], 1), _c('v-tab-item')], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }